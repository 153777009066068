export function getDateAndHourFormat(dateAndHourUnformatted: string): string {
  const dateAndHour = new Date(dateAndHourUnformatted)

  const year = dateAndHour.getFullYear()
  const month = String(dateAndHour.getMonth() + 1).padStart(2, '0')
  const day = String(dateAndHour.getDate()).padStart(2, '0')
  const hours = String(dateAndHour.getHours()).padStart(2, '0')
  const minutes = String(dateAndHour.getMinutes()).padStart(2, '0')

  return `${year}-${month}-${day} ${hours}:${minutes}`
}

export function counterTimeFormat(time: number): string {
  const minutes = Math.floor(time / 1000 / 60)
  const seconds = Math.floor((time / 1000) % 60)

  return `${minutes < 10 ? '0' : ''}${minutes}:${
    seconds < 10 ? '0' : ''
  }${seconds}`
}
