import client from '@/services/client'
import type {
  ICompany,
  ICompanyForm,
  ICompanyUnit,
  ICompanyUnitForm,
  IGetUsersCompanyUnitRoleResponse,
} from '@/types/CompaniesTypes'
import type { Nullable } from '@/types/Nullable'

import { errorsEnum } from '@/types/ErrorTypes'
import { isAxiosError } from 'axios'
import { errorsService } from '@/main'

type GetUsersCompanyUnitRolePayload = {
  companyId: number
  unitId: number
  roleId: number
}

class CompaniesRepository {
  public async getCompanies(): Promise<ICompany[]> {
    return await client
      .get<ICompany[]>(`companies`)
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetManyCompanies,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async getCompany(id: number): Promise<Nullable<ICompany>> {
    return await client
      .get(`companies/${id}`)
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetOneCompany,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async getCompanyUnits(id: number): Promise<ICompanyUnit[]> {
    return await client
      .get(`companies/${id}/units`)
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetCompanyUnits,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async addCompany(form: ICompanyForm): Promise<Nullable<ICompany>> {
    return await client
      .post(`companies`, form)
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.AddCompany,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async addCompanyUnit(
    id: number,
    form: ICompanyUnitForm
  ): Promise<Nullable<ICompanyUnit>> {
    return await client
      .post(`companies/${id}/units`, form)
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.AddCompanyUnit,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async addCompanyUnitWithParent(
    id: number,
    parentId: number,
    form: ICompanyUnitForm
  ): Promise<Nullable<ICompanyUnit>> {
    return await client
      .post(`companies/${id}/units/${parentId}`, form)
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.AddCompanyUnitWithParent,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async updateCompany(
    id: number,
    form: ICompanyForm
  ): Promise<Nullable<ICompany>> {
    return await client
      .patch(`companies/${id}`, form)
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.UpdateCompany,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async updateCompanyUnit(
    id: number,
    unitId: number,
    form: ICompanyUnitForm
  ): Promise<Nullable<ICompanyUnit>> {
    return await client
      .patch(`companies/${id}/units/${unitId}`, form)
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.UpdateCompanyUnit,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async deleteCompany(id: number): Promise<boolean> {
    return await client
      .delete(`companies/${id}`)
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.DeleteCompany,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async deleteCompanyUnit(id: number, unitId: number): Promise<boolean> {
    return await client
      .delete(`companies/${id}/units/${unitId}`)
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.DeleteCompanyUnit,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async getUsersCompanyUnitRole(
    payload: GetUsersCompanyUnitRolePayload
  ): Promise<IGetUsersCompanyUnitRoleResponse[]> {
    return await client
      .get<IGetUsersCompanyUnitRoleResponse[]>(
        `companies/${payload.companyId}/units/${payload.unitId}/roles/${payload.roleId}/users`
      )
      .then((result) => {
        return result.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.DeleteCompanyUnit,
            response: error.response,
          })
        }
        throw error
      })
  }
}

export default new CompaniesRepository()
