import mitt from 'mitt'
export type TEventBus = {
  addNewGlossaryItem: string
  glossaryInputChanged: string
  glossarySelectChanged: string
  glossaryTextAreaChanged: string
  glossaryCheckboxChanged: string
  glossaryRadioChanged: string
  saveItem: string
  endForm: boolean
}
export const eventBus = mitt<TEventBus>()
