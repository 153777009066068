import client from '@/services/client'
import { errorsService } from '@/main'
import { isAxiosError } from 'axios'
import { errorsEnum } from '@/types/ErrorTypes'

export interface ICaptcha {
  key: string
  img: string
}

export interface ICaptchaValidateResponse {
  message: string
  temporary_token: string
}
class CaptchaRepository {
  public async get(): Promise<ICaptcha> {
    return await client
      .get<ICaptcha>('captcha')
      .then((response) => {
        return response.data
      })
      .catch((e) => {
        if (isAxiosError(e)) {
          if (!e.response) throw e
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetCaptcha,
            response: e.response,
          })
        }
        throw e
      })
  }

  public async verify(
    key: string,
    captcha: string
  ): Promise<ICaptchaValidateResponse> {
    try {
      const result = await client.post<ICaptchaValidateResponse>(
        'captcha/token',
        { key, captcha }
      )
      return result.data
    } catch (e) {
      if (isAxiosError(e)) {
        if (!e.response) throw e
        errorsService.setScopeErrorsFromResponse({
          scope: errorsEnum.VerifyCaptcha,
          response: e.response,
          ignoreToast: true,
        })
      }
      throw e
    }
  }
}

export default new CaptchaRepository()
