// noinspection JSUnusedGlobalSymbols
import type { App } from 'vue'

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $scrollToElement: (
      elementRef: HTMLElement | null,
      block: ScrollLogicalPosition
    ) => void
  }
}

export default {
  install(app: App): void {
    app.config.globalProperties.$scrollToElement = (
      elementRef: HTMLElement | null,
      block: ScrollLogicalPosition = 'start'
    ): void => {
      if (elementRef) {
        elementRef.scrollIntoView({
          behavior: 'smooth',
          // @ts-ignore
          block: block,
        })
      }
    }
  },
}
