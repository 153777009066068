<template>
  <div class="c-uiStatus tw-text-center">
    <div
      :class="[
        {
          'tw-border tw-border-blue0d tw-bg-bluee7 tw-text-blue0d':
            !defaultColorGreen && blueStatuses.includes(statusLowerCase),
        },
        {
          'tw-border tw-border-green19 tw-bg-greend1 tw-text-green19':
            defaultColorGreen || greenStatuses.includes(statusLowerCase),
        },
        {
          'tw-border tw-border-black tw-bg-white tw-text-black':
            !defaultColorGreen && outlineStatuses.includes(statusLowerCase),
        },
        {
          'tw-border tw-border-brown87 tw-bg-browne7 tw-text-brown87':
            !defaultColorGreen &&
            !blueStatuses.includes(statusLowerCase) &&
            !greenStatuses.includes(statusLowerCase) &&
            !outlineStatuses.includes(statusLowerCase),
        },
        'tw-w-fit tw-rounded-md tw-border tw-px-2 tw-py-1 tw-text-xs tw-font-bold tw-uppercase',
      ]"
    >
      {{ status }}
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import type { TranslateResult } from 'vue-i18n'

@Component
export default class UIButton extends Vue {
  @Prop({ required: true })
  public status!: string | TranslateResult

  @Prop({ required: false, default: false })
  public defaultColorGreen!: boolean

  public get statusLowerCase(): string {
    return this.status?.toLowerCase() ?? ''
  }

  public blueStatuses = ['nowy', 'wprowadzanie', 'w trakcie tworzenia']
  public greenStatuses = ['wykonano', 'wprowadzony', 'otwarty']
  public outlineStatuses = [
    'procesowany',
    'potwierdzony',
    'przypisany',
    'zakończony',
    'weryfikacja',
  ]
}
</script>
