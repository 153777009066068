<template>
  <div class="c-residualRisksTab tw-mt-8">
    <UIDatatable
      :columns="columns"
      :options="options"
      :addTitle="$t('button.addPartialRisk')"
      :isShowAddButton="showAddNewButton"
      @select="routeToRisk"
      @create-new="addNewRisk"
    />
    <UIButton
      type="primary"
      class="!tw-hidden"
      :label="$t('button.generateRecord')"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import UIInput from '@/components/UI/UIInput.vue'
import UIButton from '@/components/UI/UIButton.vue'
import UIStatus from '@/components/UI/UIStatus.vue'
import { userService } from '@/main'
import assetsRepository from '@/repositories/AssetsRepository'
import type { Nullable } from '@/types/Nullable'
import type { IUserAuthUser } from '@/types/UserTypes'
import UIDatatable from '@/components/UI/UIDatatable.vue'
import type { Config, ConfigColumns } from 'datatables.net-bs5'
import { useDatatables } from '@/composables/useDatatables'

@Component({
  components: { UIDatatable, UIStatus, UIButton, UIInput },
})
export default class ResidualRisksTab extends Vue {
  @Prop({ required: false, default: true })
  public showAddNewButton!: boolean

  @Prop({ required: false, default: null })
  public assetId!: Nullable<number>

  public options!: Config

  public isSubmitting: boolean = false

  public routeToRisk(payload: { id: number }): void {
    this.$router.push(`/risks/${payload.id}`)
  }

  public get user(): Nullable<IUserAuthUser> {
    return userService.getUser
  }

  public async addNewRisk(): Promise<void> {
    if (!this.assetId || !this.user) return
    if (this.isSubmitting) return
    this.isSubmitting = true
    await assetsRepository
      .getNewAssetRisks(this.assetId, Number(this.user.id))
      .then((riskId) => {
        if (riskId) this.$router.push(`/risks/${riskId}`)
      })
      .finally(() => {
        this.isSubmitting = false
      })
  }

  public get columns(): ConfigColumns[] {
    const classStyle = 'tw-p-2.5 tw-leading-6 !tw-pl-0'
    return [
      {
        data: 'id',
        title: '#',
        className: classStyle,
      },
      {
        data: 'user_last_name',
        title: this.$t('views.assets.createdBy'),
        className: classStyle,
      },
      {
        data: 'created_at',
        title: this.$t('common.createdAt'),
        className: classStyle,
        render: function (data): string {
          const date = new Date(data)
          return new Intl.DateTimeFormat('pl-PL', {
            dateStyle: 'short',
          }).format(date)
        },
      },
      {
        data: 'risk_additional_data.rodo_risk_level',
        title: this.$t('views.assets.rodoRiskLevel'),
        className: classStyle,
        render: (data): string => {
          return data ?? '---------'
        },
      },
      {
        data: 'risk_additional_data.identification_individual_risk',
        title: this.$t('views.assets.potentialUnitRisk'),
        className: classStyle,
        render: (data): string => {
          return data ?? '---------'
        },
      },
      {
        data: 'risk_additional_data.risk_assessment_definition',
        title: this.$t('views.assets.riskEstimation'),
        className: classStyle,
        render: (data): string => {
          return data ?? '---------'
        },
      },
      {
        data: 'status.name',
        title: this.$t('common.status'),
        className: classStyle,
        render: (data): string => {
          return (
            '<div class="tw-w-fit tw-rounded-md tw-border tw-px-2 tw-py-1 tw-text-xs tw-font-bold tw-uppercase tw-border tw-border-brown87 tw-bg-browne7 tw-text-brown87">' +
            data +
            '</div>'
          )
        },
      },
    ]
  }

  public get link(): string {
    if (this.assetId) {
      return `assets/${this.assetId}/risk/list`
    }
    return `assets/risk/list`
  }

  public beforeMount(): void {
    const datatable = useDatatables(this.link)

    this.options = { ...datatable.defaultOptions }
  }
}
</script>
