<template>
  <div class="c-uiRadio tw-text-start">
    <div class="form-check ps-0 tw-relative tw-text-start">
      <input
        class="form-check-input tw-mr-1"
        :id="id"
        type="radio"
        v-bind="$attrs"
        :value="value"
        :checked="modelValue === value"
        @change="$emit('update:modelValue', value)"
        @focusin="focused = true"
        @focusout="focused = false"
      />
      <label class="form-check-label tw-relative" :for="id">
        <template v-if="label">{{ label }}</template>
        <template v-else><slot /></template>
        <span
          v-if="isTooltip"
          v-b-tooltip.hover
          :title="tooltipText"
          class="tw-absolute -tw-right-8 -tw-top-2 tw-ml-2 tw-cursor-pointer tw-text-gray-400"
        >
          <font-awesome-icon
            icon="icon fa-solid fa-circle-info"
            class="tw-mt-2 tw-text-xl"
          />
        </span>
      </label>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'

@Component({})
export default class UIRadio extends Vue {
  @Prop({ required: true, default: '' })
  public modelValue!: string

  @Prop({ required: false, default: '' })
  public value!: string | boolean

  @Prop({ required: false, default: '' })
  public label!: string

  @Prop({ required: true, default: '' })
  public id!: string

  @Prop({ required: false, default: false })
  public isTooltip!: boolean

  @Prop({ required: false, default: '' })
  public tooltipText!: string

  public focused: boolean = false
}
</script>
<style lang="scss" scoped>
.c-uiRadio
  .form-check:not(.form-switch)
  .form-check-input[type='checkbox']:checked,
.c-uiRadio
  .form-check:not(.form-switch)
  .form-check-input[type='radio']:checked {
  border: 1px solid #d81b60;
}
.form-check:not(.form-switch) .form-check-input[type='radio'] {
  width: 20px;
  height: 20px;
}
.form-check:not(.form-switch) .form-check-input[type='radio']:after {
  left: 0;
  top: 0;
  width: 14px;
  height: 14px;
}
</style>
