<template>
  <div class="tw-ml-4 tw-flex tw-items-center tw-justify-center tw-gap-4">
    <UILoader :is-loading="isLoading">
      <UIListbox
        class="tw-min-w-56"
        :calculate-width="true"
        :options="contextCompaniesOptions"
        id="contextCompanySelect"
        :model-value="contextCompanySelect"
        @update:modelValue="handleCompanyChange"
        :placeholder="$t('common.dashboard.menu.selectUnit')"
        :label="$t('common.dashboard.menu.contextCompany')"
      />
    </UILoader>
    <UILoader :is-loading="isLoading || isLoadingRoles">
      <UIListbox
        :calculate-width="true"
        v-if="contextRoleOptions.length > 0"
        :options="contextRoleOptions"
        id="contextRoleSelect"
        v-model="contextRoleSelect"
        @update:modelValue="handleRoleChange"
        :placeholder="$t('common.dashboard.menu.selectRole')"
        :class="{ 'tw-shadow-lg tw-shadow-pinkd8': highlightRole }"
        :label="$t('common.dashboard.menu.contextRole')"
      />
      <div v-else class="mx-auto tw-text-center">
        <span>{{ $t('common.noRoles') }}</span>
      </div>
    </UILoader>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Setup, Watch } from 'vue-facing-decorator'
import type { SelectOption } from '@/types/CommonTypes'
import { environmentsManager, i18n, userService } from '@/main'
import UsersManagementRepository, {
  type IUnit,
} from '@/repositories/UsersManagementRepository'
import { toast } from 'vue3-toastify'
import { onBeforeRouteUpdate } from 'vue-router'
import UILoader from '@/components/UI/UILoader.vue'
import UITransition from '@/components/UI/UITransition.vue'
import UIListbox from '@/components/UI/UIListbox.vue'

@Component({
  components: {
    UIListbox,
    UITransition,
    UILoader,
  },
})
export default class HeaderContext extends Vue {
  public contextCompanySelect: string = ''
  public contextRoleSelect: string = ''
  public contextRoleOptions: SelectOption[] = []
  public userUnits: IUnit[] = []
  public emptyRoles: Boolean = false
  public isLoading: Boolean = false
  public isLoadingRoles: Boolean = false

  public get highlightRole(): boolean {
    return userService.getHighlight
  }

  @Setup(() =>
    onBeforeRouteUpdate((_, __, next) => {
      if (environmentsManager.CONTEXT_ENABLED === 'true') {
        const userRole = userService.getUser?.context?.roleId

        if (userRole) {
          userService.setHighlightRole(false)
          next()
          return
        }

        const message = i18n.global.t('common.notifications.noRoleSelected')
        toast.error(message)
        userService.setHighlightRole(true)
        next(false)
      }
      next(true)
    })
  )
  public routeUpdate!: null

  public async getUserOptions(): Promise<void> {
    this.isLoadingRoles = true
    const user = userService.getUser
    const companyId = this.contextCompanySelect
    const userId = user?.id

    if (!companyId || !userId) return

    this.emptyRoles = false
    const userRoles = await UsersManagementRepository.getUserRoles(
      userId,
      Number(companyId)
    )

    this.contextRoleOptions = userRoles.map((role) => {
      return {
        label: role.name,

        value: String(role.id),
      }
    })
    if (this.contextRoleOptions.length === 0) {
      this.emptyRoles = true
    }

    this.isLoadingRoles = false
  }

  public async getUserUnits(): Promise<void> {
    const userId = userService.getUser?.id
    if (!userId) return
    this.userUnits = await UsersManagementRepository.getUserUnits(userId)
  }

  public async debounceReauth(): Promise<void> {
    this.isLoading = true
    await userService.reauth().finally(() => {
      this.$emit('contextChange')
      this.isLoading = false
    })
  }

  public async handleCompanyChange(value: string): Promise<void> {
    const payload = { companyContext: Number(value) }
    const userId = userService.getUser?.id
    if (!userId) return
    await UsersManagementRepository.updateUserContext(userId, payload)
      .then(async (response) => {
        try {
          this.contextCompanySelect = String(response.unit_id)
          this.contextRoleSelect = String(response.role_id)
          await this.getUserOptions()
          await this.debounceReauth()
        } catch (error) {
          console.error(error)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  public get userChangeContext(): boolean {
    return userService.changeContext
  }

  @Watch('userChangeContext')
  public handleUserAuthChange(): void {
    const context = userService.getUser?.context ?? null
    if (!context) return
    if (context.roleId) this.contextRoleSelect = context.roleId?.toString()
    if (context.unitId) this.contextRoleSelect = context.unitId?.toString()
  }

  public async handleRoleChange(value: string): Promise<void> {
    this.contextRoleSelect = value

    const payload = {
      roleContext: Number(value),
    }
    const userId = userService.getUser?.id
    if (!userId) return
    try {
      await UsersManagementRepository.updateUserContext(userId, payload)
      userService.setHighlightRole(false)
      this.debounceReauth()
    } catch (error) {
      console.error(error)
    }
  }

  public get contextCompaniesOptions(): SelectOption[] {
    const user = userService.getUser
    if (!user) return []

    return (
      user.units?.map((unit) => {
        const { name, id } = unit.company
        return {
          label: `${unit.name} (${name})`,
          value: String(unit.id),
          groupId: String(id),
          groupLabel: name,
        }
      }) ?? []
    )
  }

  public async mounted(): Promise<void> {
    this.isLoading = true
    const context = userService.getUser?.context
    const companyId = context?.unitId
    const roleID = context?.roleId
    const userId = userService.getUser?.id
    this.contextCompanySelect = String(companyId)
    this.contextRoleSelect = String(roleID)

    await this.getUserUnits()

    if (companyId && userId) {
      await this.getUserOptions()
    }
    this.isLoading = false
  }
}
</script>
