export default {
  tickets: {
    submittedBy: 'Zgłaszający',
    asset: 'Aktywo',
    systemCriticality: 'Krytyczność systemu',
    criticality: 'Krytyczność',
    notifications: 'Powiadomienia',
    all: 'Wszystkie',
    new: 'Nowy',
    open: 'Otwarty',
    assigned: 'Przypisany',
    verification: 'Weryfikacja',
    confirmed: 'Potwierdzony',
    processed: 'Procesowany',
    explanatory: 'Wyjaśniający',
    completed: 'Zakończony',
    referredToExpert: 'Przekazany do eksperta',
    none: 'Brak',
    critical: 'Krytyczny',
    important: 'Ważny',
    relevant: 'Istotny',
    lowImpact: 'Niski wpływ',
    share: 'Udostępnij',
    shareTicket: 'Udostępnij zgłoszenie',
    sharedTickets: 'Udostępnione zgłoszenia',
    sharedTicketDeleted: 'Udostępnienie zgłoszenia zostało usunięte',
    ticketAlreadyShared:
      'Zgłoszenie zostało już udostępnione temu uzytkownikowi',
    statuses: {
      1: 'Nowy',
      2: 'Otwarty',
      3: 'Przypisany',
      4: 'Weryfikacja',
      5: 'Potwierdzony',
      6: 'Procesowany',
      7: 'Wyjaśniający',
      8: 'Zakończony',
      15: 'Przekazany do eksperta',
    },
    ticketDate: 'Data zgłoszenia',
    processingStatuses: 'Statusy procesowania',
    allProcessingStatuses: 'Wszystkie statusy procesowania',
    metric: 'Metryka zgloszenia',
    newTicket: {
      chosenAttributes: 'Wybrane atrybuty',
      chosenUnit: 'Wybrana jednostka',
      chosenCompany: 'Wybrana firma',
      noUnit: 'Brak wybranej jednostki',
      noCompany: 'Brak wybranej firmy',
      newAnonymousTicket: 'Nowe anonimowe zgłoszenie',
      newTicket: 'Nowe zgłoszenie',
      institution: 'Instytucja',
      details: 'Informacje szczegółowe',
      addDetails: 'Dodaj informacje szczegółowe',
      institutionPara:
        'Wybierz firmę oraz jednostkę, do której chcesz przypisać zgłoszenie.',
      detailsPara:
        'Jeśli posiadasz szczegółowe dane na temat zdarzenia, wpisz je tutaj. Mogą to być na przykład: adres IP, nazwisko osoby związanej ze sprawą, adres e-mail, domena internetowa. Podanie informacji w odrębnych polach ułatwi przetwarzanie zgłoszenia.',
      errorEmptyAttribute: 'Atrybut nie może być pusty',
      errorEmptyField: 'Pola nie moga być puste',
      errorEmptyCompanyUnit: 'Wybierz firmę oraz jednostkę',
      ticketAssigned: 'Zgoszenie zostało przypisane',
      chooseCoordinator: 'Wybierz koordynatora',
      chooseExpert: 'Wybierz experta',
      noUsers: 'Brak dostępnych użytkowników do wybrania',
      assign: 'Przypisz',
      identify: 'Identyfikuj',
      takeOver: 'Przejmij',
      ticketDetails: 'Szczegóły zgłoszenia',
      anonymous: 'Dane anonimowe',
      anonymousPara: 'Dane anonimowe podane przez zgłaszającego',
      newSourceTitle: 'Przypisz źrodło do zgłoszenia',
      noAccessAddSource: 'Brak dostępu do dodawania źródła',
      identificationSaved: 'Identyfikacja została zapisana',
      conversation: 'Konwersacja',
      conversationPara: 'Dodaj notatkę do zgłoszenia',
      message: 'Wiadomość',
      selectCompany: 'Wybierz firmę',
      selectUnit: 'Wybierz jednostkę',
      noAttributes: 'Brak atrybutów do wybrania',
    },
    tabs: {
      general: 'Ogólne',
      identification: 'Identyfikacja',
    },
    identification: {
      newAction: 'Nowe działanie',
      noAssets: 'Brak aktyw które mogą być przypisać do zgłoszenia',
      noCategories: 'Brak kategorii do wyboru',
      listOfActions: 'Lista podjętych działań',
      actionDescription: 'Opis działania',
      addPermissions: 'Dodaj uprawnienia',
      addNote: 'Dodaj notatkę',
      saveIdentification: 'Zapisz identyfikację',
      finishIdentification: 'Zakoncz identyfikacje',
      identificationSaved: 'Identyfikacja zostala zapisana',
      revert: 'Cofnij',
      revertProcessing: 'Przywróć procesowanie',
      userIdentificationAssigned:
        'Użytkownik został przypisany do identyfikacji',
      userIdentificationDeleted:
        'Użytkownik został usunięty z identyfikacji kategorii',
      categoryTransferredToExpert: 'Kategoria została przekazana do eksperta',
      categoryTransferedToCoordinator:
        'Kategoria została przekazana do koordynatora',
      categoryFinishIdentification: 'Zakończ procesowanie',
      categoryTransferToCoordinator:
        'Przekaż kategorię do koordynatora tematycznego',
      categoryTransferToExpert: 'Przekaż kategorię do eksperta',
      endNote: 'Notatka podsumowująca',
      identificationEndComment:
        'Notatka podsumowująca zakończenie identyfikacji',
      ticketEndComment: 'Notatka podsumowująca zakończenie zgłoszenia',
      identificationDetails: 'Podsumowanie identyfikacji',
      identificationForm: 'Formularz identyfikacji',
      assignUsersToCatIden: 'Przypisz użytkowników do kategorii identyfikacji:',
      noAssetsError: 'Nie można zapisać identyfikacji bez przypisanych aktywów',
    },
    filter: {
      status: {
        all: 'Wszystkie',
        new: 'Nowe',
        open: 'Otwarty',
        assigned: 'Przypisane',
        inVerification: 'Weryfikacja',
        verified: 'Zweryfikowany',
        processed: 'Przetworzony',
        explanatory: 'Wyjaśniający',
        extended: 'Rozszerzony',
        expanded: 'Rozszerzony',
        ended: 'Zakończony',
        renewed: 'Odnowiony',
        sentToAuthority: 'Przesłany do organu',
        endedRetention: 'Zakończony - Retencja',
        closedRetention: 'Zamknięty - Retencja',
        sentResponse: 'Przesłany - Odpowiedź',
        sentExpert: 'Przesłany do eksperta',
        returnedExpert: 'Zwrócony od eksperta',
      },
    },
    modal: {
      header: 'Udziel lub zablokuj możliwość dostępu',
      description: 'Prośba o dostęp',
      user: 'Użytkownik',
      grantAccess: 'Udziel dostępu',
      denyAccess: 'Odmów dostępu',
      finishIdentificationCategoryHeader:
        'Dodaj komentarz do zakończenia identyfikacji kategorii',
      revertIdentificationCategoryHeader:
        'Dodaj komentarz do cofnięcia identyfikacji kategorii',
      finishIdentificationHeader:
        'Dodaj komentarz do zakończenia identyfikacji',
    },
    affairs: {
      affair: 'Sprawa',
      affairs: 'Sprawy',
      affairsAssigned: 'Sprawy przypisane do zgłoszenia',
      assignAffair: 'Przypisz sprawę',
      noAffairsAssigned: 'Brak przypisanych spraw do zgłoszenia',
      affairAssigned: 'Sprawa została przypisana do zgłoszenia',
      affairDeleted: 'Sprawa została usunięta z zgłoszenia',
    },
    anonymous: {
      yourDetails: 'Twoje dane',
      yourDetailsPara:
        'Podaj swoje dane, w celu ewentualnego kontaktu w sprawie zgłoszenia.',
      yourTicketNumber: 'Numer twojego zgłoszenia',
      ticketAccess: 'Możesz podejrzeć status danego zgłoszenia',
      here: 'tutaj',
      gainAccess: 'Aby uzyskać dostęp do zgłoszenia, użyj kodu',
      timeLeft: 'Pozostały czas do zakończenia zapisu zgłoszenia',
      saveData: 'Zapisz dane instytucji i użytkownika',
      dataSaved: 'Dane zostały zapisane',
      ticketSubmitDisclaimer:
        'Na twój adres email (jeżeli został podany) niedługo powinno przyjść powiadomienie z linkiem do podglądu statusu zgłoszenia.',
      anonTicketSubmitted: 'Zgłoszenie anonimowe zostało zapisane',
      anonTicketSubmitedPara:
        'Jeśli podałeś adres e-mail, otrzymasz wiadomość z linkiem do podglądu statusu zgłoszenia. W przypadku braku adresu e-mail, przed zamknięciem strony zalecamy zapisanie tego linku. Możesz również wydrukować stronę, aby zachować wszystkie informacje.',
      createNewAnonymous: 'Utwórz nowe anonimowe zgłoszenie',
      currentTicketStatus: 'Obecny status zgłoszenia',
      submitter: 'Zgłaszający',
      coordinator: 'Koordynator',
      verifyTicket: 'Wpisz kod weryfikacyjny pin',
    },
    activityLog: 'Dziennik aktywności',
  },
}
