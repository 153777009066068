<template>
  <div
    class="c-dashboardCounter time tw-mb-0 tw-flex tw-items-center tw-justify-center tw-gap-4 tw-text-xs"
  >
    <div class="tw-flex tw-items-center tw-gap-2">
      <span>
        {{ $t('common.headerTime') }}
      </span>
      <span class="tw-text-sm tw-font-bold tw-tracking-wider">{{ timer }}</span>
    </div>

    <UIButton
      type=""
      size="medium"
      button-class="tw-text-black !tw-mb-0"
      :disabled="isReauthInProgress"
      @click="extendSession"
    >
      <template #left>
        <div
          class="hover:tw-opacity-50"
          :class="isReauthInProgress && 'tw-animate-spin'"
        >
          <font-awesome-icon
            icon="icon fa-solid fa-arrows-rotate"
            class="tw-text-lg"
          />
        </div>
      </template>
    </UIButton>
  </div>
  <SessionTimeoutModal
    :timer="timer"
    @logout="handleSessionTimeout"
    @extendsession="extendSession"
  />
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { environmentsManager, userService } from '@/main'
import SessionTimeoutModal from '@/components/layouts/dashboardLayout/SessionTimeoutModal.vue'
import { expiredSessionModalService } from '@/main'
import UIButton from '@/components/UI/UIButton.vue'
import { counterTimeFormat } from '@/helpers/getDateAndHourFormat'
import { LocalStorageKeys } from '@/types/LocalStorageKeys'
import { routeNames } from '@/router/types'

@Component({
  components: { UIButton, SessionTimeoutModal },
})
export default class DashboardCounter extends Vue {
  public timer: string = ''
  public modalShown: boolean = false
  public isReauthInProgress: boolean = false

  public get countdownStartInSeconds(): number {
    return Number(environmentsManager.MODAL_SHOW_LIFETIME) * 60
  }

  public async handleLogout(): Promise<void> {
    expiredSessionModalService.close()
    this.modalShown = false
    userService.clearUserData()
    this.$router.push({ name: routeNames.Logout })
  }

  public handleSessionTimeout(): void {
    this.handleLogout()
  }

  public async extendSession(): Promise<void> {
    try {
      this.isReauthInProgress = true
      await userService.regenerateToken()
    } catch (e) {
      console.error(e)
    } finally {
      this.isReauthInProgress = false
      this.modalShown = false
    }
  }

  public getLocalStorageCounter(): string | null {
    const value = localStorage.getItem(LocalStorageKeys.COUNTER)
    if (value) return value
    return null
  }

  public getStartTime(): number {
    const counter = this.getLocalStorageCounter()
    if (!counter) return 0
    return Number(counter)
  }

  public getTime(): number {
    const currentDate = new Date()
    const startTime = this.getStartTime()
    return startTime - Number(currentDate)
  }

  public handleCalcTime(interval?: ReturnType<typeof setInterval>): void {
    const timeLeft = this.getTime()
    const isMinuteLeft = timeLeft <= this.countdownStartInSeconds * 1000
    const shouldShowModal = isMinuteLeft && !this.modalShown
    if (timeLeft <= 0) {
      this.handleSessionTimeout()
      if (interval) clearInterval(interval)
      return
    } else if (shouldShowModal) {
      expiredSessionModalService.open()
      this.modalShown = true
    }
    this.timer = counterTimeFormat(timeLeft)
  }

  public mounted(): void {
    this.handleCalcTime()
    // https://stackoverflow.com/questions/45802988/typescript-use-correct-version-of-settimeout-node-vs-window
    const interval: ReturnType<typeof setInterval> = setInterval(() => {
      this.handleCalcTime(interval as ReturnType<typeof setInterval>)
    }, 1000)
  }
}
</script>
