import { ref } from 'vue'
import type { Config } from 'datatables.net-bs5'
import { environmentsManager, i18n, userService } from '@/main'

type UseDatatablesType = {
  defaultOptions: Config
  defaultColumnStyle: string
  constructEndpoint: (endpoint: string) => string
}

type CSVOptions = {
  name: string
}

type UseDatatableOptions = {
  query: string
}

export const useDatatables = (
  endpoint: string,
  csvOptions?: CSVOptions,
  options?: UseDatatableOptions
): UseDatatablesType => {
  const { t } = i18n.global
  const language = ref({
    processing: t('datatables.processing'),
    search: t('datatables.search'),
    lengthMenu: t('datatables.lengthMenu'),
    info: t('datatables.info'),
    infoEmpty: t('datatables.infoEmpty'),
    infoFiltered: t('datatables.infoFiltered'),
    loadingRecords: t('datatables.loadingRecords'),
    zeroRecords: t('datatables.zeroRecords'),
    emptyTable: t('datatables.emptyTable'),
    aria: {
      orderable: t('datatables.aria.orderable'),
      orderableReverse: t('datatables.aria.orderableReverse'),
      sortAscending: t('datatables.aria.sortAscending'),
      sortDescending: t('datatables.aria.sortDescending'),
    },
  })

  const { BACKEND_PERMISSIONS_ENABLED } = environmentsManager
  options = (options || {}) as UseDatatableOptions

  if (options?.query?.length > 0) {
    options.query += `&`
  } else {
    options.query = `?`
  }

  if (BACKEND_PERMISSIONS_ENABLED === 'false') {
    options.query += `debug[PERMISSIONS_ENABLED]=false`
  }

  const token = (): string => {
    return userService.getToken()
  }

  const constructEndpoint = (endpoint: string): string => {
    const API_URL: string = import.meta.env.VITE_API_URL
    if (!API_URL) {
      throw new Error('API_URL not defined')
    }
    return `${API_URL}api/v1/${endpoint}`
  }

  const createFilename = (name: string): string => {
    const date = new Date()
    const timestamp =
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1) +
      '-' +
      date.getDate() +
      '_' +
      date.getHours() +
      '-' +
      date.getMinutes() +
      '-' +
      date.getSeconds()
    return `${timestamp}_${name}`
  }

  const defaultOptions: Config = {
    serverSide: true,
    stateSave: true,
    stateSaveCallback: function (settings, data) {
      localStorage.setItem(`Datatable_${endpoint}`, JSON.stringify(data))
    },
    stateLoadCallback: function () {
      const data = localStorage.getItem(`Datatable_${endpoint}`)
      if (!data) return ''
      return JSON.parse(data)
    },
    language: language.value,
    ajax: {
      // @ts-ignore -- wrong typings on Datatables part
      url: `${constructEndpoint(endpoint)}${options?.query ?? ''}`,
      error: function (xhr: unknown, error: unknown, thrown: unknown) {
        console.error('Datatable fetch error: ', xhr, error, thrown)
      },
      headers: {
        Authorization: `Bearer ${token()}`,
      },
    },
    select: true,
    scrollX: true,
    buttons: true,
    layout: {
      topStart: [],
      topEnd: [],
      bottom2End: {
        // @ts-ignore -- wrong typings on Datatables part
        buttons: [
          {
            extend: 'copy',
            text: t('datatables.copy'),
            titleAttr: t('datatables.copyTooltip'),
            className:
              '!tw-border !tw-border-greyd2 !tw-text-sm !tw-font-semibold hover:tw-opacity-75 focus:!tw-shadow-none !tw-bg-transparent !tw-text-black dark:!tw-text-greyc9 tw-mr-2',
          },
          {
            extend: 'csv',
            text: t('datatables.csv'),
            titleAttr: t('datatables.csvTooltip'),
            className:
              '!tw-border !tw-border-greyd2 !tw-text-sm !tw-font-semibold hover:tw-opacity-75 focus:!tw-shadow-none !tw-bg-transparent !tw-text-black dark:!tw-text-greyc9',
            filename: createFilename(csvOptions?.name ?? ''),
            exportOptions: {
              page: 'all',
            },
          },
        ],
      },
    },
  }

  const defaultColumnStyle =
    'tw-p-2.5 tw-leading-6 !tw-pl-0 hover:tw-cursor-pointer'

  return {
    constructEndpoint,
    defaultOptions,
    defaultColumnStyle,
  }
}
