<template>
  <div
    class="c-notificationsList tw-absolute -tw-left-[100px] tw-z-50 -tw-ml-10 tw-mt-2 tw-max-h-[60vh] tw-w-[340px] tw-overflow-y-auto tw-rounded-xl tw-bg-white tw-pt-3 tw-shadow-xl dark:tw-bg-grey21"
  >
    <h3 class="tw-mb-4 tw-px-4 tw-text-lg tw-font-medium tw-tracking-wide">
      {{ $t('views.notifications.title') }}
    </h3>

    <div
      class="tw-mb-4 tw-border-b tw-border-gray-200 tw-px-4 tw-font-medium tw-text-gray-500 dark:tw-border-gray-700 dark:tw-text-gray-400"
    >
      <ul class="-tw-mb-px tw-flex tw-flex-wrap tw-pl-0">
        <li class="tw-me-2 tw-cursor-pointer">
          <p
            class="tw-mb-0 tw-inline-block tw-rounded-t-lg tw-border-b-2 tw-border-transparent tw-p-2 tw-text-sm tw-font-medium hover:tw-border-gray-300 hover:tw-border-b-black hover:tw-text-black hover:tw-duration-500 dark:tw-text-white dark:hover:tw-text-gray-300"
            :class="{ 'tw-border-b-black tw-text-black': isActiveTab === 0 }"
            @click="isActiveTab = 0"
          >
            {{ $t('views.notifications.all') }}
          </p>
        </li>
        <li
          class="tw-me-2 tw-cursor-pointer"
          v-for="type in types"
          :key="type.id"
          v-show="notificationsForTypeExist(type.id)"
        >
          <p
            class="tw-mb-0 tw-inline-block tw-rounded-t-lg tw-border-b-2 tw-border-transparent tw-p-2 tw-text-sm tw-font-medium hover:tw-border-gray-300 hover:tw-border-b-black hover:tw-text-black hover:tw-duration-500 dark:hover:tw-text-gray-300"
            :class="{
              'tw-border-b-black tw-text-black': isActiveTab === type.id,
            }"
            @click="isActiveTab = type.id"
          >
            {{ type.name }}
          </p>
        </li>
      </ul>
    </div>
    <h3
      class="tw-pl-4 tw-text-center tw-text-xs tw-font-bold tw-uppercase tw-tracking-wide tw-text-blue19"
      v-if="todayNotifications.length === 0 && otherNotifications.length === 0"
    >
      {{ $t('views.notifications.no-notifications') }}
    </h3>
    <template v-if="todayNotifications.length > 0">
      <h3
        class="tw-pl-4 tw-text-xs tw-font-bold tw-uppercase tw-tracking-wide tw-text-blue19"
      >
        {{ $t('views.notifications.today') }}
      </h3>
      <ul class="tw-pl-0">
        <li
          class="tw-cursor-pointer hover:tw-bg-[#eff2f3] hover:tw-duration-500 hover:dark:tw-bg-grey21"
          :class="{
            'tw-flex tw-items-center tw-bg-[#eff2f3] dark:tw-bg-grey55':
              notification.read_at === null,
          }"
          v-for="notification in todayNotifications"
          :key="`notification-${notification.id}`"
        >
          <div class="tw-px-4 tw-py-2" @click="goToAssigment(notification)">
            <h4 class="tw-text-base">{{ notification.message }}</h4>
            <p class="tw-mb-0 tw-text-sm">
              {{ notification.assignment?.title }}
            </p>
            <p class="tw-mb-0 tw-mt-2 tw-text-xs tw-text-slate-400">
              {{ notification.company_unit.name ?? '' }} -
              {{ notification.role.name ?? '' }}
            </p>
          </div>
          <span
            v-if="notification.read_at === null"
            class="p-1 tw-mr-2 tw-block tw-rounded-full tw-bg-brand"
            @click.prevent="markAsRead(notification)"
          ></span>
          <span
            v-if="notification.read_at !== null"
            class="p-1 tw-absolute tw-right-3 -tw-mt-14 tw-rounded-full"
            @click.prevent="deleteNotification(notification)"
          >
            <font-awesome-icon icon="icon fa-solid fa-trash" />
          </span>
        </li>
      </ul>
    </template>
    <template v-if="otherNotifications.length > 0">
      <h3
        class="tw-pl-4 tw-text-xs tw-font-bold tw-uppercase tw-tracking-wide tw-text-blue19"
      >
        {{ $t('views.notifications.other') }}
      </h3>
      <ul class="tw-pl-0">
        <li
          class="tw-cursor-pointer hover:tw-bg-[#eff2f3] hover:tw-duration-500"
          :class="{
            'tw-flex tw-items-center tw-bg-[#eff2f3]':
              notification.read_at === null,
          }"
          v-for="notification in otherNotifications"
          :key="`notificationOther-${notification.id}`"
          @click="goToAssigment(notification)"
        >
          <div class="tw-relative tw-px-4 tw-py-2">
            <h4 class="tw-text-base">{{ notification.message }}</h4>
            <p class="tw-mb-0 tw-text-sm">
              {{ notification.assignment?.title ?? '' }}
            </p>
            <p class="tw-mb-0 tw-mt-2 tw-text-xs tw-text-slate-400">
              {{ notification.company_unit.name ?? '' }} -
              {{ notification.role.name ?? '' }}
            </p>
          </div>
          <span
            v-if="notification.read_at === null"
            class="p-1 tw-mr-2 tw-block tw-rounded-full tw-bg-brand"
            @click.prevent="markAsRead(notification)"
          ></span>
          <span
            v-if="notification.read_at !== null"
            class="p-1 tw-absolute tw-right-3 -tw-mt-14 tw-rounded-full"
            @click.prevent="deleteNotification(notification)"
          >
            <font-awesome-icon icon="icon fa-solid fa-trash" />
          </span>
        </li>
      </ul>
    </template>
    <div class="tw-flex tw-justify-between">
      <template v-if="isReadedNotifications">
        <button
          class="tw-px-4 tw-pb-4 tw-text-xs"
          @click="deleteAllReadNotifications()"
        >
          {{ $t('views.notifications.deleteAllReadedNotifications') }}
        </button>
      </template>
      <template v-if="isNotReadedNotifications">
        <button class="tw-px-4 tw-pb-4 tw-text-xs" @click="markAllRead()">
          {{ $t('views.notifications.markAllAsRead') }}
        </button>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import type { INotification, INotificationType } from '@/types/TasksTypes'
import taskRepository from '@/repositories/TaskRepository'
import { routeNames } from '@/router/types'
import { taskService, userService } from '@/main'
import UsersManagementRepository from '@/repositories/UsersManagementRepository'

@Component
export default class NotificationsList extends Vue {
  public deletedElement: boolean = false
  public isActiveTab: number = 0
  public types: INotificationType[] = []
  public routeNames: typeof routeNames = routeNames

  public async loadTypes(): Promise<void> {
    await taskRepository.getNotificationsTypes().then((response) => {
      this.types = response
    })
  }

  public get notifications(): INotification[] {
    return taskService.notifications
  }

  public notificationsForTypeExist(id: number): boolean {
    const not =
      this.notifications.filter((notification) => id === notification.type_id)
        .length ?? 0
    return not > 0
  }

  public get isNotReadedNotifications(): boolean {
    return this.notifications.some(
      (notification) => notification.read_at === null
    )
  }

  public get isReadedNotifications(): boolean {
    return this.notifications.some(
      (notification) => notification.read_at !== null
    )
  }

  public get todayNotifications(): INotification[] {
    if (this.isActiveTab === 0) {
      return this.notifications.filter((notification) =>
        this.isToday(new Date(notification.created_at))
      )
    }
    return this.notifications.filter(
      (notification) =>
        this.isToday(new Date(notification.created_at)) &&
        this.isActiveTab === notification.type_id
    )
  }

  public get otherNotifications(): INotification[] {
    if (this.isActiveTab === 0) {
      return this.notifications.filter(
        (notification) => !this.isToday(new Date(notification.created_at))
      )
    } else {
      return this.notifications.filter(
        (notification) =>
          !this.isToday(new Date(notification.created_at)) &&
          this.isActiveTab === notification.type_id
      )
    }
  }

  public async debounceReauth(): Promise<void> {
    await userService.reauth().then(() => {
      userService.reloadChangeContext()
    })
  }

  public async handleRoleChange(unitId: number, roleId: number): Promise<void> {
    const context = userService.getUser?.context ?? null
    const userId = userService.getUser?.id
    if (!userId) return
    if (context?.unitId === unitId && context.roleId === roleId) return
    try {
      if (context?.unitId !== unitId) {
        const payload = {
          companyContext: unitId,
        }
        await UsersManagementRepository.updateUserContext(userId, payload)
      }
      if (context?.roleId !== roleId) {
        const payload = {
          roleContext: roleId,
        }
        await UsersManagementRepository.updateUserContext(userId, payload)
      }

      if (context?.unitId !== unitId || context?.roleId !== roleId) {
        userService.setHighlightRole(false)
        const currentRoute = this.$route

        if (currentRoute.name === this.routeNames.Tasks) {
          window.location.reload()
        } else {
          await this.debounceReauth()
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  private isToday(date: Date): boolean {
    const today = new Date()
    return (
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate()
    )
  }

  public async mounted(): Promise<void> {
    await this.loadTypes()
  }

  public async markAsRead(notification: INotification): Promise<void> {
    if (!this.deletedElement) {
      await this.handleRoleChange(
        notification.company_unit_id,
        notification.role_id
      ).then(async () => {
        await taskRepository
          .markAsReadNotification(notification.id)
          .then(async () => {
            notification.read_at = new Date().toString()
            await userService.restoreNotificationSummary()
          })
      })
    }
  }

  public async markAllRead(): Promise<void> {
    if (!this.deletedElement) {
      await taskRepository.markAsReadNotification(null).then(async () => {
        await Promise.all([
          userService.restoreNotificationSummary(),
          taskService.loadNotifications(),
        ])
      })
    }
  }

  public async deleteAllReadNotifications(): Promise<void> {
    this.deletedElement = true
    await taskRepository.deleteAllReadNotifications().then(async () => {
      await Promise.all([
        userService.restoreNotificationSummary(),
        taskService.loadNotifications(),
      ]).finally(() => {
        this.deletedElement = false
      })
    })
  }

  public async deleteNotification(notification: INotification): Promise<void> {
    this.deletedElement = true
    await this.handleRoleChange(
      notification.company_unit_id,
      notification.role_id
    ).then(async () => {
      await taskRepository
        .deleteNotification(notification.id)
        .then(async () => {
          await Promise.all([
            userService.restoreNotificationSummary(),
            taskService.loadNotifications(),
          ]).finally(() => {
            this.deletedElement = false
          })
        })
    })
  }

  public async goToAssigment(notification: INotification): Promise<void> {
    const message = notification.assignment?.title ?? ''
    if (this.deletedElement) return
    await this.handleRoleChange(
      notification.company_unit_id,
      notification.role_id
    ).then(async () => {
      await taskRepository
        .markAsReadNotification(notification.id)
        .then(async () => {
          await userService.restoreNotificationSummary().then(() => {
            const currentRoute = this.$route

            if (currentRoute.name === this.routeNames.Tasks) {
              window.location.reload()
            }

            this.$router.push({
              name: this.routeNames.Tasks,
              query: { search: message },
            })

            this.$emit('close')
          })
        })
    })
  }
}
</script>
