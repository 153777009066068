export default {
  notifications: {
    consentsAccepted: 'Zgoda została zaakceptowana pomyślnie',
    consentsAcceptedError: 'Wystąpił błąd podczas akceptacji zgody',
    saveSuccess: 'Zmiany zostały zapisane.',
    unitDeleteSuccess: 'Jednostka została usunięta poprawnie.',

    errors: {
      fillAllFields: 'Uzupełnij wszystkie pola',
      passwordsNotEqual: 'Hasła nie są takie same',
      default: 'Coś poszło nie tak',
      '400': 'Nieprawidłowe dane',
      '401': 'Użytkownik jest nieautoryzowany',
      '403': 'Użytkownik nie ma uprawnień do wykonania tej akcji',
      '404': 'Nie znaleziono zasobu',
      '413': 'Za dużo danych',
      '429': 'Zbyt wiele prób, spróbuj ponownie za chwilę',
      '422': 'Nieprawidłowe dane',
      '500': 'Wystąpił błąd serwera',
      twoFactorInvalidMethodChangeRequest:
        'Nieprawidłowa prośba o zmianę metody uwierzytelniania',
      twoFactorInvalidCode: 'Nieprawidłowy kod uwierzytelniający',
      twoFactorCodeExpired: 'Kod uwierzytelniający wygasł',
      twoFactorInvalidSecret: 'Niepraidłowy sekret uwierzytelniający',
      twoFactorMethodChangeRequestExpired:
        'Prośba o zmianę metody uwierzytelniania wygasła',
      twoFactorMethodChangeUnauthorized:
        'Nieautoryzowana zmiana metody uwierzytelniania',
      twoFactorDisabled: 'Uwierzytelnianie dwuetapowe jest wyłączone',
      userNotActive: 'Użytkownik nie jest aktywny',
      emailNotVerified: 'Email nie został zweryfikowany',
      invalidCredentials: 'Nieprawidłowe dane uwierzytelniające',
      rateLimited: 'Zbyt wiele prób, spróbuj ponownie za chwilę',
      emailAlreadyVerified: 'Email jest już zweryfikowany',
      resetAlreadyRequested: 'Wniosek o reset już został złożony',
    },
  },
}
