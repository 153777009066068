import { Store, Pinia } from 'pinia-class-component'
import type {
  IError,
  IParsedError,
  IScopeError,
  IScopeErrorsPayload,
  IScopesErrors,
} from '@/types/ErrorTypes'
import { toast } from 'vue3-toastify'
import { i18n } from '@/main'
import debounce from 'lodash/debounce'
import isArray from 'lodash/isArray'

@Store
export default class ErrorsService extends Pinia {
  public scopesErrors: IScopesErrors = {}
  public debouncedErrors: Record<string, () => void> = {}

  public setScopesErrors(payload: IScopeError): void {
    this.scopesErrors[payload.scope] = payload.errors as IError
  }

  public getScopeErrors(scope: string): IError {
    if (!this.scopesErrors[scope]) {
      this.scopesErrors[scope] = {} as IError
    }
    return this.scopesErrors[scope]
  }

  public displayMessage(payload: IScopeErrorsPayload, status: number): void {
    const response = payload.response.data as IError | IParsedError[]
    let message: string = ''

    const translationExists = i18n.global.te(`notifications.errors.${status}`)

    if (isArray(response)) {
      // We are assuming that the first error is the most important
      // Since errors can come in the form of an array
      if (response[0].message && response[0].key) {
        const keyTranslation = i18n.global.te(
          `notifications.errors.${response[0].key}`
        )
        if (keyTranslation) {
          message = i18n.global.t(`notifications.errors.${response[0].key}`)
        } else {
          message = response[0].message as string
        }
      }
    } else if (translationExists) {
      message = i18n.global.t(`notifications.errors.${status}`)
    } else {
      message = i18n.global.t('notifications.errors.default')
    }

    if (payload) {
      if (!this.debouncedErrors[payload.scope]) {
        this.debouncedErrors[payload.scope] = debounce(() => {
          toast(message, {
            type: 'error',
            position: 'top-right',
          })
        }, 500)
      }

      this.debouncedErrors[payload.scope]()
      return
    }

    toast(message, {
      type: 'error',
      position: 'top-right',
    })
  }

  public setErrors(payload: IScopeErrorsPayload, status: number): void {
    const response = payload.response.data as IError | IParsedError

    this.setScopesErrors({
      scope: payload.scope,
      errors: response as IError,
      status: status,
    })

    if (payload.ignoreToast) return

    this.displayMessage(payload, status)
  }

  public setErrorsNotFound(payload: IScopeErrorsPayload): void {
    const message = i18n.global.t('notifications.errors.default')

    if (payload.ignoreToast) return
    toast(message, {})
  }

  public clearScopeErrors(scope: string): void {
    this.setScopesErrors({ scope, errors: {} as IError, status: 0 })
  }

  public setScopeErrorsFromResponse(payload: IScopeErrorsPayload): void {
    this.setScopesErrors({
      scope: payload.scope,
      errors: {} as IError,
      status: 0,
    })

    if (payload.response.status) {
      const { status } = payload.response
      this.setErrors(payload, status)
      return
    }

    this.setErrorsNotFound(payload)
  }
}
