// https://stackoverflow.com/a/65248576
/**
 * Returns a JS object representation of a Javascript Web Token from its common encoded
 * string form.
 *
 * @template T the expected shape of the parsed token
 * @param {string} token a Javascript Web Token in base64 encoded, `.` separated form
 * @returns {(T | undefined)} an object-representation of the token
 * or undefined if parsing failed
 */
export function decodeJWT<T extends object = { [k: string]: string | number }>(
  token: string
): T | undefined {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch {
    return undefined
  }
}

/**
 * @param {string} token a Javascript Web Token in base64 encoded, `.` separated form
 * @returns the expiration date of the token or undefined if the token is invalid
 */
export function getExpirationDate(token: string): Date | undefined {
  const decoded = decodeJWT(token)

  if (!decoded || typeof decoded.exp !== 'number') return

  return new Date(decoded.exp * 1000)
}
