import type { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { environmentsManager, loaderService, userService } from '@/main'

export function setTimeoutInterceptor(
  client: AxiosInstance,
  waitTime: number
): void {
  client.interceptors.request.use(
    (config) => {
      // @ts-ignore
      config.timeoutId = setTimeout(() => {
        loaderService.setLoadingRequest(true)
      }, waitTime) as unknown as number
      return config
    },
    (error: AxiosError) => {
      loaderService.setLoadingRequest(false)
      return Promise.reject(error)
    }
  )

  client.interceptors.response.use(
    (response: AxiosResponse) => {
      loaderService.setLoadingRequest(false)
      // @ts-ignore
      if (response.config.timeoutId) {
        // @ts-ignore
        clearTimeout(response.config.timeoutId)
      }
      return response
    },
    (error: AxiosError) => {
      loaderService.setLoadingRequest(false)

      // @ts-ignore
      if (error?.config?.timeoutId) {
        // @ts-ignore
        clearTimeout(error?.config?.timeoutId)
      }
      return Promise.reject(error)
    }
  )
}

export function setAuthorizationInterceptor(client: AxiosInstance): void {
  client.interceptors.request.use(
    (config) => {
      const token = userService.getToken()

      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      // Add the permission query parameter to the URL
      const { BACKEND_PERMISSIONS_ENABLED } = environmentsManager

      const url = new URL(config.baseURL! + '/' + config.url!)

      if (BACKEND_PERMISSIONS_ENABLED === 'false') {
        const debugParams: Record<string, string> = {
          PERMISSIONS_ENABLED: 'false',
        }

        Object.entries(debugParams).forEach(([key, value]) => {
          url.searchParams.append(`debug[${key}]`, value)
        })
      }

      // Update the config with the modified URL
      config.url = url.toString()
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}
