<template>
  <div class="c-uiTabs">
    <button
      class="tab"
      :class="{
        active: isActive(tab),
        '!tw-cursor-not-allowed !tw-bg-white !tw-text-greyc9 dark:!tw-bg-neutral-950 dark:!tw-text-greyc9':
          tab.disabled,
      }"
      v-for="tab in tabs"
      :key="tab.value"
      @click="$emit('setActive', tab)"
    >
      {{ tab.label }}
    </button>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { type ITab } from '@/types/TabsTypes'

@Component({})
export default class UITabs extends Vue {
  @Prop()
  public tabs!: ITab[]

  @Prop()
  public active!: string

  public isActive(tab: ITab): boolean {
    return tab.value === this.active
  }
}
</script>

<style lang="scss" scoped>
.c-uiTabs {
  display: flex;
  width: 100%;
  justify-content: space-around;
  gap: 8px;
}
.tab {
  flex: 1;
  border-radius: 8px;
  display: flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--header-color);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  transition: all 0.2s linear;

  &:hover {
    color: #d71b49;
  }

  &.active {
    color: var(--header-color);
    background-color: var(--ui-bg-color);
    box-shadow: 0 2px 6px #00000040;
  }
}
</style>
