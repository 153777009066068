<template>
  <b-dropdown class="user-dropdown m-md-2">
    <template #button-content>
      <div
        class="user tw-flex tw-items-center tw-text-black dark:tw-text-greyc9"
      >
        <font-awesome-icon
          icon="fa-solid fa-circle-user"
          class="tw-mr-2 tw-text-2xl"
        />
        <p class="tw-mb-0 tw-text-sm tw-normal-case">{{ userName }}</p>
      </div>
    </template>
    <b-dropdown-item v-for="link in menu" :key="`menu-${link.label}`">
      <router-link
        :to="link.route"
        class="link tw-text-black tw-no-underline dark:tw-text-greyc9"
      >
        <span
          class="icon tw-mr-2 !tw-text-lg tw-text-black dark:tw-text-greyc9"
          v-if="link.icon"
        >
          <font-awesome-icon :icon="link.icon" />
        </span>
        <span class="link">{{ link.label }}</span>
      </router-link>
    </b-dropdown-item>

    <HeaderDarkModeButtons />

    <b-dropdown-item @click="logout">
      <span class="icon tw-mr-2 !tw-text-lg tw-text-black dark:tw-text-greyc9">
        <font-awesome-icon icon="fa-solid fa-right-from-bracket" />
      </span>
      <span class="link">{{ $t('common.sessionTimeout.buttons.logout') }}</span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import { userService } from '@/main'
import HeaderDarkModeButtons from '@/components/layouts/header/HeaderDarkModeButtons.vue'
import { routeNames } from '@/router/types'

interface DropdownMenuItem {
  icon: string
  label: string
  route: string
}
@Component({
  components: {
    HeaderDarkModeButtons,
  },
})
export default class HeaderMenu extends Vue {
  public get menu(): DropdownMenuItem[] {
    return [
      {
        icon: 'icon fa-solid fa-gear',
        label: this.$t('menu.profileSettings'),
        route: '/profile/settings',
      },
    ]
  }

  public get userName(): string {
    const firstName = userService.getUser?.firstName
    const lastName = userService.getUser?.lastName
    return `${firstName} ${lastName ?? ''}`
  }

  public logout(): void {
    userService.logout()
    this.$router.push({ name: routeNames.Logout })
  }
}
</script>

<style lang="scss" scoped>
.user-dropdown {
  :deep(.dropdown-toggle) {
    display: flex;
    align-items: center;
    background-color: transparent;
    padding: 0;
    margin: 0;
    box-shadow: none;

    &::after {
      color: var(--header-color);
      font-size: 16px;
    }

    &:active {
      background-color: white !important;
      box-shadow: none;
    }
  }
  :deep(.dropdown-menu) {
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
      0 4px 6px -4px rgb(0 0 0 / 0.1);
    left: -13px !important;
    top: 11px !important;
  }
  :deep(.dropdown-item) {
    line-height: 26px;
    display: flex;
    align-items: center;
    &:hover {
      .icon,
      .link {
        color: #344767 !important;
        transition-duration: 500ms;
      }
    }
  }
}
</style>
