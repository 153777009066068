import { errorsService } from '@/main'
import client from '@/services/client'
import { errorsEnum } from '@/types/ErrorTypes'
import type {
  IAssignment,
  IStatus,
  ITaskAddForm,
  IType,
  ITaskActionTakeOver,
  ITaskActionDelegate,
  ITaskActionClose,
  INotificationSetting,
  INotification,
  INotificationType,
  GetTaskFilters,
  INotificationSettingForm,
} from '@/types/TasksTypes'
import { isAxiosError } from 'axios'
import type { IUser } from '@/types/UserTypes'
import type { Nullable } from '@/types/Nullable'

class TaskRepository {
  public async getTasks(filters?: GetTaskFilters): Promise<IAssignment[]> {
    let payloadFilters: undefined | URLSearchParams
    if (filters) {
      const filterBy = [filters]
      const acc = [filterBy]

      const payload = new URLSearchParams()
      payload.append('filterBy', JSON.stringify(acc))
      payloadFilters = payload
    }

    return await client
      .get<IAssignment[]>(`assignments`, {
        params: payloadFilters,
      })
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetManyTasks,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async getTask(id: string): Promise<IAssignment> {
    return await client
      .get<IAssignment>(`assignments/${id}`)
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetManyTasks,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async getStatuses(): Promise<IStatus[]> {
    return await client
      .get<IStatus[]>(`assignments/statuses`)
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetTaskStatuses,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async getTypes(): Promise<IType[]> {
    return await client
      .get<IType[]>(`assignments/types`)
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetTaskTypes,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async addTask(task: ITaskAddForm): Promise<void> {
    return await client
      .post(`assignments`, task)
      .then(() => {})
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.CreateTask,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async updateAction(
    taskId: number,
    action: ITaskActionTakeOver | ITaskActionDelegate | ITaskActionClose
  ): Promise<void> {
    return await client
      .patch(`assignments/${taskId}`, action)
      .then(() => {})
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.UpdateAction,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async getNotifications(): Promise<INotification[]> {
    return await client
      .get<INotification[]>(`assignments/notifications`)
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetNotifications,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async getNotificationsTypes(): Promise<INotificationType[]> {
    return await client
      .get<INotificationType[]>(`assignments/notifications/types`)
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetNotificationsTypes,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async addNotificationSetting(
    notificationSettingForm: INotificationSettingForm
  ): Promise<void> {
    return await client
      .post(`assignments/notifications/settings`, notificationSettingForm)
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.AddNotificationSetting,
            response: error.response,
            ignoreToast: true,
          })
        }

        throw error
      })
  }

  public async getNotificationsSettings(): Promise<INotificationSetting[]> {
    return await client
      .get<INotificationSetting[]>(`assignments/notifications/settings `)
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetNotificationsSettings,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async getNotificationSetting(
    id: number
  ): Promise<INotificationSetting> {
    return await client
      .get<INotificationSetting>(`assignments/notifications/settings/${id}`)
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetNotificationSetting,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async updateNotificationSetting(
    id: number,
    notificationSettingForm: INotificationSettingForm
  ): Promise<void> {
    return await client
      .put(`assignments/notifications/settings/${id}`, notificationSettingForm)
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.AddNotificationSetting,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async markAsReadNotification(id: Nullable<number>): Promise<void> {
    return await client
      .patch(`assignments/notifications/read`, { notificationId: id })
      .then(() => {
        return
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.NotificationAsRead,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async deleteAllReadNotifications(): Promise<void> {
    await client
      .delete(`assignments/notifications/read`)

      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.NotificationAsRead,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async deleteNotification(id: Nullable<number>): Promise<void> {
    const searchId = id ? id : ''
    return await client
      .delete(`assignments/notifications/${searchId}`)
      .then(() => {
        return
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.NotificationDelete,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async getAvailableDelegates(id: number): Promise<IUser[]> {
    return await client
      .get<IUser[]>(`assignments/${id}/available-delegates`)
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetAvailableDelegates,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async deleteNotificationSetting(id: number): Promise<void> {
    return await client
      .delete(`assignments/notifications/settings/${id}`)
      .then(() => {
        return
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.DeleteNotificationSetting,
            response: error.response,
          })
        }

        throw error
      })
  }
}

export default new TaskRepository()
